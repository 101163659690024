import AppHeader from "../components/app-header";
import LegalFooter from "../components/legal-footer";

const Billing: React.FC = () => {
  return (
    <>
      <AppHeader />

      <div className="flex flex-col items-center justify-center">
        <div className="mt-8 px-6 shadow-md rounded-md">
          <h1 className="text-2xl font-bold mb-4">Billing Information</h1>
          <p className="text-gray-300">
            To ensure uninterrupted service, all Trainer.ai App subscriptions
            are set to renew automatically, utilizing the credit card
            information on record.
          </p>
          <p className="text-gray-300 mt-4">
            The monthly and annual subscriptions will be charged at the time of
            purchase and will automatically renew at the conclusion of the
            respective monthly or annual term, at the prevailing rate
            established during the initial purchase. The subscription will
            continue to renew automatically until it is canceled.
          </p>
          <p className="text-gray-300 mt-4">
            Kindly take note that your credit card will be charged upon
            confirmation of purchase, and all sales are considered final, with
            no provision for refunds. We do not offer reimbursements or credits
            for instances such as changes of mind, unused subscription periods,
            accidental purchases, medical conditions, or any similar reasons or
            events, unless legally obligated to do so.
          </p>
          <p className="text-gray-300 mt-4">
            Subscriptions will renew automatically unless you deactivate the
            auto-renewal feature at least 24 hours prior to the end of the
            current period. To cancel or disable automatic renewal, please
            access the Trainer.ai app, navigate to the Profile tab, select the
            Subscriptions section, and choose the option to Cancel Subscription.
          </p>
          <p className="text-gray-300 mt-4">
            By subscribing to Trainer.ai, you acknowledge and agree to abide by
            our Privacy Policy and Terms of Use.
          </p>
        </div>
      </div>
      <LegalFooter />
    </>
  );
};

export default Billing;
