import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { useOptimizedImage } from "../helpers";
import { User as Creator } from "../types";
import SubscribeButton from "./subscribe-button";

interface Props {
  creator: Creator;
}

export default function CreatorHeader({ creator }: Props) {
  const { coverUrl, fullname, bio, username, profileUrl } = creator;
  return (
    <>
      <div className="relative">
        <div className="z-10 relative">
          <img
            className="h-48 w-full object-cover lg:h-80 xl:h-80"
            src={coverUrl}
            alt={fullname}
          />
          <div
            className="overlay"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "linear-gradient(to bottom, transparent, rgba(25, 25, 28, 0.7))",
            }}
          />
        </div>
        <div className="absolute inset-0 bg-black opacity-50" />
      </div>
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8 relative z-20">
        <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
          <div className="flex">
            <img
              className="h-24 w-24 object-contain rounded-full ring-8 ring-background sm:h-32 sm:w-32"
              src={useOptimizedImage(profileUrl)}
              alt={fullname}
            />
          </div>
          <div className="mt-6 mb-1 sm:flex sm:min-w-0 sm:flex-1 sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
            <div className="mt-6 min-w-0 flex-1 sm:hidden md:block flex-row flex items-center">
              <h1 className="truncate font-poppins text-2xl font-semibold text-white">
                {fullname}
              </h1>
              <h1 className="flex items-center ml-1.5 mt-0.5">
                <span className="text-gray-300 mr-1 font-poppins italic text-sm">
                  {`@${username}`}
                </span>
                <span className="inline-block rounded-full bg-white border-none">
                  <CheckBadgeIcon className="h-4 w-4 text-blue-500" />
                </span>
              </h1>
            </div>
          </div>

          <div className="sm:hidden">
            <span className="text-gray-300 mr-1 font-poppins text-sm truncate">
              {bio}
            </span>
          </div>
          <div className="hidden sm:block">
            <SubscribeButton username={username} />
          </div>
        </div>
        <div className="mt-6 hidden min-w-0 flex-1 sm:block md:hidden truncate">
          <h1 className="truncate text-2xl font-bold text-gray-900">
            {fullname}
          </h1>
        </div>
      </div>
    </>
  );
}
