import { useParams } from "react-router-dom";
import AppHeader from "../components/app-header";
import Divider from "../components/divider";
import LegalFooter from "../components/legal-footer";
import ScreenshotScrollview from "../components/screenshot-scroll";
import SmallCreatorHeader from "../components/small-creator-header";
import Subscriptions from "../components/subscriptions";
import { useCreator } from "../services/creator.service";
import NotFoundScreen from "./404";
import LoadingPage from "./loading";
import ReviewsScrollView from "../components/reviews";
import { useEffect } from "react";
import { IOS_URL, ANDROID_URL } from "../constants";

export default function CreatorSubscriptions() {
  const { username } = useParams();
  const { creator, loading } = useCreator(username);

  useEffect(() => {
    if (navigator.userAgent.includes("Instagram")) {
      // @ts-ignore
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // @ts-ignore
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const isAndroid = /Android/.test(userAgent);

      if (isIOS) {
        window.location.href = IOS_URL;
      } else if (isAndroid) {
        window.location.href = ANDROID_URL;
      }
    }
  }, []);

  if (loading) return <LoadingPage />;
  if (!creator) return <NotFoundScreen />;

  return (
    <>
      <AppHeader />
      <div className="flex flex-col pt-4">
        <SmallCreatorHeader creator={creator} />

        <ReviewsScrollView />
        <Divider />
        <div className="p-8">
          <Subscriptions creator={creator} />
        </div>

        <ScreenshotScrollview />

        <LegalFooter />
      </div>
    </>
  );
}
