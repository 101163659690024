import React from "react";
import stepone from "../img/one.jpg";
import steptwo from "../img/two.jpg";
import AppHeader from "../components/app-header";
import LegalFooter from "../components/legal-footer";
import { ClipboardIcon } from "@heroicons/react/20/solid";

export default function DefaultBrowser() {
  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText("https://trainer-ai.com");
  };

  return (
    <>
      <AppHeader />
      <div className="mt-6 flex flex-col justify-center px-4">
        <p>We noticed you're using Instagram's in-app browser.</p>
        <p className="my-2">
          Unfortunately, Instagram's in-app browser doesn't support Google login
          needed to create your account and subscribe to this creator.
        </p>
        <p>To subscribe to this creator, please follow the steps below.</p>
        <div className="my-2">
          <p className="text-sm" onClick={copyLinkToClipboard}>
            Or copy and paste this link into your default browser:
          </p>
          <button
            onClick={copyLinkToClipboard}
            type="button"
            className="mt-4 inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Copy Link
            <ClipboardIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="m-2 mt-6 items-center flex flex-col">
          <h2 className="text-lg font-bold">Step 1</h2>
          <p className="text-sm">
            Tap the dropdown menu in the top right corner of instagram's
            browser.
          </p>
          <img src={stepone} alt="Step One" className="w-60" />
        </div>
        <div className="m-2 mt-6 items-center flex flex-col">
          <h2 className="text-lg font-bold">Step 2</h2>
          <p className="text-sm">
            Tap "Open in System Browser" to open the app in your default
            browser.
          </p>
          <img src={steptwo} alt="Step Two" className="w-60" />
        </div>
        <LegalFooter />
      </div>
    </>
  );
}
