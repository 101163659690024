const reviews = [
  {
    id: 1,
    name: "Tysin",
    comment:
      "App provides many diverse workout plans and shows proper technique and form of movements. Would recommend for beginners and advanced lifters!",
  },
  {
    id: 2,
    name: "Aaron",
    comment:
      "Trainer.ai is a really great fitness app that offers personalized workouts based on your fitness goals and preferences. The app is so easy to use and I will definitely stick with this. Clarence's training is really useful and has continued to help me work towards my fitness goals.",
  },
  {
    id: 3,
    name: "Sobie",
    comment:
      "The videos are really helpful in addition to the workouts and its easy to navigate everything in the app. Great app!",
  },
  {
    id: 4,
    name: "Suze",
    comment:
      "I’ve put on 10 lbs of pure muscle since starting Clarence’s program. App is well designed and super intuitive.",
  },
  {
    id: 5,
    name: "Evan",
    comment: "App is easy to use and does what I need it to",
  },
];

const stars = new Array(5).fill(0);
const HorizontalScrollView = () => {
  return (
    <div className="flex overflow-x-auto p-2 mr-2">
      {reviews.map((item) => (
        <div
          key={item.id}
          className={`w-60 bg-white/5 rounded-lg flex-shrink-0 mr-4`}
        >
          <div className="flex flex-col items-start h-full p-4">
            <div className="flex items-center">
              {stars.map((_, index) => (
                <span key={index} className="text-orange-300 text-lg">
                  ★
                </span>
              ))}
            </div>
            <p className="mt-2 text-orange-100 font-poppins text-sm">
              {item.comment}
            </p>
            <p className="mt-2 text-orange-400 font-poppins text-sm">
              - {item.name}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default HorizontalScrollView;
