import { useNavigate } from "react-router-dom";
import { ProgramPlan } from "../types";

interface Props {
  programs: ProgramPlan[];
  username: string;
}

export default function ProgramGridList({ programs, username }: Props) {
  const navigate = useNavigate();
  function navigateToPrograms() {
    navigate(`/${username}/programs`);
  }
  return (
    <>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="flex items-center justify-between">
          <h2 className="lg:text-xl text-md font-bold tracking-tight text-gray-100 font-poppins">
            Trending Programs
          </h2>
          <div
            onClick={navigateToPrograms}
            className=" text-sm font-medium text-blue-400 hover:text-blue-500 md:block"
          >
            View all
            <span aria-hidden="true"> &rarr;</span>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {programs.map((product) => (
            <div
              onClick={navigateToPrograms}
              key={product.id}
              className="group relative"
            >
              <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80 md:h-44 sm:h-44">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  className="h-full w-full object-cover object-center"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
