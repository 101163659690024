import { PlanType } from "../constants";
import { User as Creator } from "../types";

interface Subscription {
  text: string;
  planId: string;
  planType: PlanType;
  description: string;
  priceDetails: {
    id: string;
    price: number;
    currency: string;
  } | null;
}

interface Props {
  creator: Creator;
  subscription: Subscription;
  changePlan: (planId: string) => void;
}

export default function UnselectedSubscription({
  creator,
  subscription,
  changePlan,
}: Props) {
  const type = subscription.planType === PlanType.MONTHLY ? "Month" : "Yearly";
  return (
    <div
      onClick={() => changePlan(subscription.planId)}
      key={subscription.planId}
      className="relative w-full md:w-1/2 lg:w-1/3 xl:w-1/4 flex items-center space-x-3 rounded-lg border border-gray-600 bg-white/5 px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-500 cursor-pointer"
    >
      <div className="flex-shrink-0">
        <img
          className="h-14 w-14 rounded-full"
          src={creator.profileUrl}
          alt={creator.fullname}
        />
      </div>
      <div className="min-w-0 flex-1">
        <div>
          <div className="inline-block">
            <p className="text-md font-medium text-gray-100">
              {subscription.text}
            </p>
            <p className="flex items-baseline gap-x-1">
              <span className="text-sm font-bold tracking-tight text-gray-300">
                ${subscription.priceDetails?.price}{" "}
                {subscription.priceDetails?.currency.toLocaleUpperCase()}
              </span>
              <span className="text-sm font-semibold leading-6 text-gray-400">
                / {type}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
