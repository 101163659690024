import { useLazyQuery } from "@apollo/client";
import {
  CalendarDaysIcon,
  CheckCircleIcon,
  LockOpenIcon,
  VideoCameraIcon,
} from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { PlanType, TOKEN } from "../constants";
import { classNames } from "../helpers";
import { GET_STRIPE_CHECKOUT_SESSION } from "../services/stripe.service";
import { User } from "../types";
import ReactGA from "react-ga4";

interface Subscription {
  text: string;
  planId: string;
  planType: PlanType;
  description: string;
  priceDetails: {
    id: string;
    price: number;
    currency: string;
  } | null;
}

interface Props {
  subscription: Subscription;
  creator: User;
  changePlan: (planId: string) => void;
}

const points = (name: string) => [
  {
    icon: LockOpenIcon,
    text: `Access to all of ${name}'s fitness programs, content, and exercises`,
  },
  {
    icon: VideoCameraIcon,
    text: "Fully recorded fitness programs with exercise videos and workout instructions",
  },
  {
    icon: CalendarDaysIcon,
    text: "New fitness content continuously updated with new programs and exercises every month",
  },
  {
    icon: CheckCircleIcon,
    text: "Fully integrated with progress tracking, workout history, exercise analytics, and more",
  },
];

export default function SelectedSubscription({
  subscription,
  creator,
  changePlan,
}: Props) {
  const navigate = useNavigate();
  const [getStripeCheckout, { loading: checkoutLoading }] = useLazyQuery<{
    getStripeCheckout: { url: string };
  }>(GET_STRIPE_CHECKOUT_SESSION);
  const { text, description, planId, planType, priceDetails } = subscription;
  const type = planType === PlanType.MONTHLY ? "Monthly" : "Yearly";
  const features = points(creator.firstName);
  const programImages = creator.programs.map((program) => program.imageUrl);
  const yearly = planType === PlanType.YEARLY;
  async function handleCheckout(planId: string) {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
      navigate("/auth");
      return;
    }
    const { data } = await getStripeCheckout({
      variables: { planId, isProd: process.env.NODE_ENV === "production" },
    });
    if (!data) {
      alert("Something went wrong. Please try again later.");
      return;
    }
    const uri = data.getStripeCheckout.url;
    window.location.href = uri;
    ReactGA.event({
      category: "Subscription",
      action: "Checkout",
      label: planId,
    });
  }

  return (
    <div
      onClick={() => changePlan(planId)}
      key={planId}
      className="bg-white/5 ring-2 ring-red-400 rounded-xl p-6 xl:p-10 w-full md:w-1/2 lg:w-1/3 xl:w-1/4"
    >
      <div className="flex items-center justify-between gap-x-4">
        <h3 id={planId} className="text-lg font-semibold leading-8 text-white">
          {text}
        </h3>

        {yearly && (
          <p className="rounded-full bg-red-400 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
            Most popular
          </p>
        )}
      </div>
      <p className="mt-4 text-sm leading-6 text-gray-300">{description}</p>
      <p className="mt-6 flex items-baseline gap-x-1">
        <span className="text-2xl font-bold tracking-tight text-white">
          <del className="text-red-400">
            {planType === PlanType.MONTHLY ? "$14.99" : "$129.99"}
          </del>{" "}
          ${priceDetails?.price} {priceDetails?.currency.toLocaleUpperCase()}
        </span>
        <span className="text-sm font-semibold leading-6 text-gray-300">
          / {type}
        </span>
      </p>

      <div className="mt-6 flex flex-row">
        {programImages.slice(0, 4).map((imageUrl) => (
          <div key={imageUrl} className="mr-2">
            <img
              src={imageUrl}
              alt="program"
              className="w-44 h-24 object-cover rounded-md"
            />
          </div>
        ))}
      </div>

      <button
        onClick={() => handleCheckout(planId)}
        disabled={checkoutLoading}
        className={classNames(
          " bg-red-400  text-white shadow-sm hover:bg-red-400 focus-visible:outline-red-500",
          "mt-6 block rounded-md py-2 px-3 text-center text-md font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 w-full"
        )}
      >
        {checkoutLoading ? "Loading..." : "Subscribe now"} &rarr;
      </button>
      <div className="mt-8 space-y-3 text-sm leading-6 text-gray-300 xl:mt-10">
        {features.map((feature, index) => (
          <div key={index} className="flex gap-x-3">
            <feature.icon className="h-6 w-5 flex-none text-white" />
            {feature.text}
          </div>
        ))}
      </div>
    </div>
  );
}
