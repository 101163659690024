import { useEffect } from "react";
import { ANDROID_URL, IOS_URL } from "../constants";
import LoadingPage from "./loading";

export enum DeviceType {
  Android,
  iOS,
  Browser,
}

export default function AppRedirect() {
  useEffect(() => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // @ts-ignore
    const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(userAgent);

    if (isIOS) {
      window.location.href = IOS_URL;
    } else if (isAndroid) {
      window.location.href = ANDROID_URL;
    }
  }, []);

  return <LoadingPage />;
}
