import { useNavigate } from "react-router-dom";
import BackgroundVideo from "../components/background-video";
import GoogleIcon from "../icons/google";
import Logo from "../img/logo.png";

const uri = process.env.REACT_APP_GOOGLE_AUTH_URL;

export default function Auth() {
  const navigate = useNavigate();
  function handleLogin() {
    if (navigator.userAgent.includes("Instagram")) {
      navigate("/instagram");
    } else {
      window.open(uri, "_self");
    }
  }

  return (
    <>
      <BackgroundVideo />
      <div className="absolute inset-0 flex flex-col items-center justify-end pb-10 lg:pb-20">
        <img src={Logo} alt="logo" className="h-10 lg:h-14" />
        <h1 className="uppercase font-poppins font-semibold text-xs lg:text-sm mb-6 mt-4">
          Your one stop shop for all things fitness
        </h1>
        <div className="w-full max-w-sm px-8 lg:px-2">
          <button
            onClick={handleLogin}
            type="button"
            className="flex items-center justify-center rounded-md w-full bg-white px-4 py-3 lg:px-5 lg:py-3 shadow-lg hover:bg-gray-50 hover:shadow-none"
          >
            <GoogleIcon />
            <span className="ml-1.5 mt-0.25 font-poppins text-lg font-medium text-black">
              Continue with Google
            </span>
          </button>

          <div>
            <div className="mt-6 text-center text-xs lg:text-sm">
              By continuing, you agree to our{" "}
              <a className="hover:underline" href="https://google.com">
                Terms of Service
              </a>{" "}
              and{" "}
              <a className="hover:underline" href="https://google.com">
                Privacy Policy
              </a>
              .
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
