import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { CreatorLink } from "../types";

interface Props {
  link: CreatorLink;
}

export default function LinkItem({ link: creatorLink }: Props) {
  const { imageUrl, name, description, link } = creatorLink;
  return (
    <a href={link} key={name} className="flex justify-between gap-x-6 py-5">
      <div className="flex gap-x-4">
        <img
          className="h-12 w-12 flex-none rounded-full bg-gray-800 object-cover"
          src={imageUrl}
          alt={name}
        />
        <div className="min-w-0 flex-auto">
          <p className="text-md font-poppins font-semibold leading-6 text-white">
            {name}
          </p>
          <p className="mt-1  font-poppins text-sm leading-5 text-gray-400">
            {description}
          </p>
        </div>
      </div>
      <div className="sm:flex sm:flex-col sm:items-end">
        <ChevronRightIcon className="h-5 w-5 text-gray-100" />
      </div>
    </a>
  );
}
