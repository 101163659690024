import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { TOKEN } from "../constants";
import { classNames } from "../helpers";
import logo from "../img/logo.png";
import LoadingPage from "../pages/loading";
import { useMe } from "../services/user.service";

export default function AppHeader() {
  const navigate = useNavigate();
  const { me, loading } = useMe();

  if (loading) return <LoadingPage />;

  function handleSignout() {
    localStorage.removeItem(TOKEN);
    navigate("/");
  }

  return (
    <Disclosure as="nav" className="bg-background z-50 shadow-xl">
      {() => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="block h-7 w-auto lg:hidden"
                    src={logo}
                    alt="trainerai"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src={logo}
                    alt="trainerai"
                  />
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <Menu as="div" className="relative ml-3">
                  <div>
                    {me ? (
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={me.profileUrl}
                          alt={me.firstName}
                        />
                      </Menu.Button>
                    ) : (
                      <div
                        onClick={() => navigate("/auth")}
                        className="text-sm lg:text-md font-semibold leading-6 text-gray-200 hover:text-white cursor-pointer"
                      >
                        Sign Up <span aria-hidden="true">&rarr;</span>
                      </div>
                    )}
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-700" : "",
                              "block px-4 py-2 text-sm text-gray-200"
                            )}
                            onClick={() => navigate("/app")}
                          >
                            Download App
                          </div>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <div
                            className={classNames(
                              active ? "bg-gray-700" : "",
                              "block px-4 py-2 text-sm text-gray-200"
                            )}
                          >
                            Your Account
                          </div>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={handleSignout}
                            className={classNames(
                              active ? "bg-gray-700" : "",
                              "block px-4 py-2 text-sm text-gray-200"
                            )}
                          >
                            Sign out
                          </div>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
}
