import { useEffect, useState } from "react";
import { PlanType } from "../constants";
import { User as Creator } from "../types";
import SelectedSubscription from "./selected-subscription";
import UnselectedSubscription from "./unselected-subscription";
import ReactGA from "react-ga4";

interface Props {
  creator: Creator;
}

export default function Subscriptions({ creator }: Props) {
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const subscriptions = creator.creatorSubscriptions
    .map(({ planId, planType, priceDetails }) => {
      const type = planType === PlanType.MONTHLY ? "Monthly" : "Yearly";

      return {
        text: `${type} Plan`,
        description:
          "When you subscribe below, your subscription automatically activates in our mobile app available on iOS and Android app stores.",
        planId,
        planType,
        priceDetails,
      };
    })
    .sort(({ planType }) => (planType === PlanType.MONTHLY ? 1 : -1));

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: `/${creator.username}/subscriptions`,
      title: `${creator.fullname} Subscription Page`,
    });
    if (creator.creatorSubscriptions.length > 0) {
      const yearly = creator.creatorSubscriptions.find(
        ({ planType }) => planType === PlanType.YEARLY
      );
      if (yearly) {
        setSelectedPlan(yearly.planId);
      } else {
        setSelectedPlan(creator.creatorSubscriptions[0].planId);
      }
    }
  }, [creator]);

  const changePlan = (planId: string) => setSelectedPlan(planId);

  return (
    <div className="flex flex-col gap-y-4 justify-center items-center">
      {subscriptions.map((subscription) =>
        selectedPlan === subscription.planId ? (
          <SelectedSubscription
            key={subscription.planId}
            subscription={subscription}
            creator={creator}
            changePlan={changePlan}
          />
        ) : (
          <UnselectedSubscription
            key={subscription.planId}
            subscription={subscription}
            creator={creator}
            changePlan={changePlan}
          />
        )
      )}
    </div>
  );
}
