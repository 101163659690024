export default function Divider() {
  return (
    <div className="relative flex py-4 items-center w-full">
      <div className="flex-grow border-t border-gray-300" />
      <span className="flex-shrink mx-4">
        <h1 className="text-gray-100 font-poppins text-sm mt-1">
          You save{" "}
          <span className="text-orange-200 font-poppins font-semibold">
            ~20%
          </span>{" "}
          subscribing here vs. in-app
        </h1>
      </span>
      <div className="flex-grow border-t border-gray-300" />
    </div>
  );
}
