import { gql, useQuery } from "@apollo/client";
import { User } from "../types";

export const ME = gql`
  query me {
    me {
      id
      email
      username
      firstName
      lastName
      fullname
      profileUrl
      verified
      bio
      content
      loggedWorkoutCount
      daysPerWeek
      loggedWorkoutWeeklyStreak
      weekLoggedWorkouts
      currentPlanId
      currentPlanStart
      isOnboarded
      experience
    }
  }
`;

export function useMe() {
  const { data, loading, refetch, error } = useQuery(ME, {
    fetchPolicy: "network-only",
  });
  const me: User = data?.me;
  return { me, loading, refetch, error };
}
