import React from "react";

const BackgroundVideo = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);

  React.useEffect(() => {
    const { current: videoElement } = videoRef;
    if (!videoElement) return;
    videoElement.setAttribute("muted", "");
    videoElement.playbackRate = 0.75;
    videoElement.play();
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="absolute inset-0 overflow-hidden z-negative bg-black">
      <video
        src="/videos/bg.mp4"
        loop
        muted
        ref={videoRef}
        playsInline
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: isMobile ? "175%" : "0%",
          minHeight: isMobile ? "175%" : "0%",
          transform: "translate(-50%, -50%)",
          overflow: "hidden",
        }}
      />
      <div className="absolute inset-0 overflow-hidden bg-black bg-opacity-75" />
    </div>
  );
};

export default BackgroundVideo;
