import { ProgramPlan } from "../types";

interface Props {
  program: ProgramPlan;
}

export default function ProgramCard({ program }: Props) {
  return (
    <div key={program.id} className="group relative">
      <div className="h-48 w-32 overflow-hidden rounded-md bg-gray-200 group-hover:opacity-60 lg:h-72 xl:h-80 snap-center">
        <img
          src={program.imageUrl}
          alt={program.name}
          className="h-full w-full object-cover object-center"
        />
      </div>
    </div>
  );
}
