import { Buffer } from "buffer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CREATOR_USERNAME, TOKEN } from "../constants";
import LoadingPage from "./loading";

export default function AuthSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    const url = new URL(window.location.href);
    const tokenParam = url.searchParams.get("token");
    if (tokenParam) {
      const decoded = Buffer.from(tokenParam, "hex").toString();
      console.log("decoded", decoded);
      localStorage.setItem(TOKEN, decoded);
      const username = localStorage.getItem(CREATOR_USERNAME);
      navigate(`/${username}/subscriptions`);
    }
  }, [navigate]);

  return <LoadingPage />;
}
