import Lottie from "lottie-react";
import BackgroundImage from "../img/background.png";
import loadingAnimation from "../lotties/loading.json";

export default function LoadingPage() {
  return (
    <div
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="flex min-h-screen flex-col items-center justify-center"
    >
      <div className="mb-2 sm:mx-auto sm:w-full sm:max-w-md">
        <Lottie
          animationData={loadingAnimation}
          className="h-16 lg:h-24"
          loop
        />
      </div>
    </div>
  );
}
