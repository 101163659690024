import { useNavigate, useParams } from "react-router-dom";
import { useCreator } from "../services/creator.service";
import CreatorHeader from "../components/creator-header";
import LegalFooter from "../components/legal-footer";
import { TOKEN } from "../constants";
const uri = process.env.REACT_APP_GOOGLE_AUTH_URL;
export default function CreatorPrograms() {
  const navigate = useNavigate();
  const { username } = useParams();
  const { creator, loading } = useCreator(username);

  if (loading) return <div>Loading...</div>;
  const products = creator.programs;

  function handleSubscribePress() {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
      window.open(uri);
    } else {
      navigate(`/${username}/subscriptions`);
    }
  }

  return (
    <div>
      <CreatorHeader creator={creator} />
      <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-xl font-bold text-gray-100 font-poppins">
          My Programs
        </h2>

        <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <div key={product.id}>
              <div className="relative">
                <div className="relative h-72 w-full overflow-hidden rounded-lg">
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="relative mt-4">
                  <h3 className="text-md font-medium text-gray-100 font-poppins">
                    {product.name}
                  </h3>
                  <p
                    className="mt-1 text-sm text-gray-500 font-poppins"
                    style={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {product.description}
                  </p>
                </div>
                <div className="absolute inset-x-0 top-0 flex h-72 items-end justify-end overflow-hidden rounded-lg p-4">
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 bottom-0 h-36 bg-gradient-to-t from-black opacity-50"
                  />
                </div>
              </div>
              <div className="mt-2.5" onClick={handleSubscribePress}>
                <div className="relative flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-8 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200">
                  Subscribe to Program &rarr;
                  <span className="sr-only">, {product.name}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <LegalFooter />
    </div>
  );
}
