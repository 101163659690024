import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ReactNode } from "react";
import { TOKEN } from "../constants";
const uri = process.env.REACT_APP_API_URL;
const httpLink = createHttpLink({
  uri: `${uri}/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}` ?? undefined,
    },
  };
});

interface Props {
  children: ReactNode;
}

export const APOLLO_CLIENT = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default function GraphQLProvider({ children }: Props) {
  return <ApolloProvider client={APOLLO_CLIENT}>{children}</ApolloProvider>;
}
