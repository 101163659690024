export const TOKEN = "token";
export const CREATOR_USERNAME = "creatorUsername";
export const IOS_URL = "https://apps.apple.com/ca/app/trainer-ai/id6444597107";
export const ANDROID_URL =
  "https://play.google.com/store/apps/details?id=com.daylonball.Trainerai";
export const DEEPLINK_URL = "trainerai://";
export const WEB_URL = "https://www.linkedin.com/company/trainerai/";
export enum PlanType {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum ExternalUrls {
  TermsOfService = "https://docs.google.com/document/d/e/2PACX-1vRan81eIkikxTYzPSF7O2-XFKnHtYO_lQuplHAQiaOKY6vjmrX6IB7JJq2VEi-P5A/pub",
  PrivacyPolicy = "https://docs.google.com/document/d/e/2PACX-1vRTICTMrC-HLPySqo5TKhWfv1um96Fi5-1xEzRTYdxS5P5-KIhGvKae1yk4zcrPBw/pub",
}
