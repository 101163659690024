import { useEffect, useRef, useState } from "react";
import five from "../img/screenshots/five.png";
import four from "../img/screenshots/four.png";
import one from "../img/screenshots/one.png";
import three from "../img/screenshots/three.png";
import two from "../img/screenshots/two.png";

const images = [one, two, three, four, five];

export default function ScreenshotScrollview() {
  const [activeIndex, setActiveIndex] = useState(0);
  const autoPlayRef = useRef<any>();

  useEffect(() => {
    autoPlayRef.current = goNext;
  });

  useEffect(() => {
    const play = () => {
      autoPlayRef.current();
    };

    const interval = setInterval(play, 4000);
    return () => clearInterval(interval);
  }, []);

  const goNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="mx-auto py-4">
      <img
        src={images[activeIndex]}
        alt="screenshot"
        className="h-96 object-contain snap-center"
      />
      <div className="flex justify-center my-2 space-x-2.5">
        {images.map((_, idx) => (
          <button
            onClick={() => setActiveIndex(idx)}
            className={`w-2.5 h-2.5 rounded-full ${
              activeIndex === idx ? "bg-gray-200" : "bg-gray-500"
            }`}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
}
