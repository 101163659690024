import { CreatorLink } from "../types";
import LinkItem from "./link-item";
interface Props {
  links: CreatorLink[];
}

export default function CreatorLinks({ links }: Props) {
  const sortedLinks = links.slice().sort((a, b) => a.position - b.position);
  return (
    <>
      <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-2 lg:max-w-7xl lg:px-8">
        <div className="md:flex md:items-center md:justify-between">
          <h2 className="lg:text-xl text-lg font-bold tracking-tight text-gray-100 font-poppins">
            My Links
          </h2>
          <div className="hidden text-md font-medium text-blue-400 hover:text-blue-500 md:block" />
        </div>
      </div>
      <div className="flex items-center justify-center m-4">
        <div
          role="list"
          className="w-full sm:w-2/3 md:w-1/2 lg:w-1/2 divide-y divide-gray-800 mx-auto"
        >
          {sortedLinks.map((link) => (
            <LinkItem link={link} />
          ))}
        </div>
      </div>
    </>
  );
}
