import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import AppRedirect from "./pages/app-redirect";
import Auth from "./pages/auth";
import AuthSuccess from "./pages/auth-success";
import Billing from "./pages/billing";
import CreatorPage from "./pages/creator";
import CreatorPrograms from "./pages/creator-programs";
import CreatorSubscriptions from "./pages/creator-subscriptions";
import DefaultBrowser from "./pages/default-browser";
import Success from "./pages/success";
const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/instagram",
    element: <DefaultBrowser />,
  },
  {
    path: "/auth",
    element: <Auth />,
  },
  {
    path: "/billing",
    element: <Billing />,
  },
  {
    path: "/:username",
    element: <CreatorPage />,
    loader: ({ params }) => {
      return { username: params.username };
    },
  },
  {
    path: "/:username/subscriptions",
    element: <CreatorSubscriptions />,
  },
  {
    path: "/success/:username/:planId",
    element: <Success />,
  },
  {
    path: "/:username/programs",
    element: <CreatorPrograms />,
  },
  {
    path: "/app",
    element: <AppRedirect />,
  },
  {
    path: "/auth-success",
    element: <AuthSuccess />,
    loader: ({ params }) => {
      return { token: params.token };
    },
  },
  {
    path: "*",
    element: <div>404</div>,
  },
]);

export default router;
