import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { User } from "../types";

interface Props {
  creator: User;
}

export default function SmallCreatorHeader({ creator }: Props) {
  const navigate = useNavigate();

  return (
    <div
      className="flex justify-center items-center py-4 cursor-pointer"
      onClick={() => navigate(`/${creator.username}`)}
    >
      <img
        className="h-12 w-12 object-contain rounded-full ring-2 ring-gray-700"
        src={creator.profileUrl}
        alt={creator.fullname}
      />
      <div className="ml-4">
        <h3 className="text-lg font-semibold leading-8 text-white font-poppins">
          {creator.fullname}
        </h3>
        <h1 className="flex items-center">
          <span className="text-gray-300 mr-1 font-poppins italic text-sm">
            {`@${creator.username}`}
          </span>
          <span className="inline-block rounded-full bg-gray-200 border-none">
            <CheckBadgeIcon className="h-4 w-4 text-blue-500" />
          </span>
        </h1>
      </div>
    </div>
  );
}
