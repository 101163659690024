export const useOptimizedImage = (url?: string) => {
  if (!url) {
    return "";
  }
  return url.replace("upload", "upload/q_60/w_500/f_auto/");
};

export function classNames(...classes: (string | undefined)[]) {
  return classes.filter(Boolean).join(" ");
}
