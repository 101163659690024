import { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import CreatorHeader from "../components/creator-header";
import CreatorLinks from "../components/creator-links";
import LegalFooter from "../components/legal-footer";
import ProgramGrid from "../components/program-grid";
import ProgramScrollview from "../components/program-scrollview";
import SubscribeButton from "../components/subscribe-button";
import { CREATOR_USERNAME } from "../constants";
import "../index.css";
import { useCreator } from "../services/creator.service";
import NotFoundScreen from "./404";
import LoadingPage from "./loading";

interface Props {
  username: string;
}

export default function CreatorPage() {
  const { username } = useLoaderData() as Props;
  const { creator, loading } = useCreator(username);

  useEffect(() => {
    if (!creator) return;
    const { fullname } = creator;
    document.title = `${fullname} | Fitness App | Trainer.ai`;
    localStorage.setItem(CREATOR_USERNAME, username);
  }, [creator, username]);

  if (loading) return <LoadingPage />;
  if (!creator) return <NotFoundScreen />;

  return (
    <>
      <CreatorHeader creator={creator} />
      <div className="sm:hidden">
        <SubscribeButton username={username} />
      </div>
      <div className="sm:hidden">
        <ProgramScrollview programs={creator.programs} username={username} />
      </div>
      <div className="hidden sm:block">
        <ProgramGrid
          programs={creator.programs.slice(0, 4)}
          username={username}
        />
      </div>
      <CreatorLinks links={creator.links} />
      <LegalFooter />
    </>
  );
}
