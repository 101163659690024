import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { User } from "../types";

export const GET_CREATOR = gql`
  query GetCreator($username: String!, $platform: String) {
    creator(username: $username) {
      id
      username
      firstName
      fullname
      profileUrl
      coverUrl
      bannerUrl
      bio
      content
      creatorSubscriptions(platform: $platform) {
        planId
        planType
        priceDetails {
          price
          currency
        }
      }
      links {
        id
        name
        link
        description
        imageUrl
        position
      }
      programs {
        name
        imageUrl
        description
      }
    }
  }
`;

export function useCreator(username?: string) {
  const platform =
    process.env.NODE_ENV === "production" ? "stripe" : "stripe_test";
  const { data, loading, refetch, error } = useQuery(GET_CREATOR, {
    variables: { username, platform },
  });

  const creator: User = data?.creator;
  return { creator, loading, refetch, error };
}
