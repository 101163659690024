import Empty from "../img/empty.jpeg";
import Logo from "../img/logo.png";
export default function NotFoundScreen() {
  return (
    <>
      <main className="relative isolate min-h-screen">
        <img
          src={Empty}
          alt="empty-gym"
          className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          style={{ filter: "brightness(45%)" }}
        />
        <div className="absolute inset-0 flex items-center justify-center px-8">
          <div className="text-center">
            <img src={Logo} alt="logo" className="mx-auto h-12 w-auto" />
            <h1 className="mt-4 text-2xl font-bold tracking-tight text-white sm:text-5xl">
              404: Page not found
            </h1>
            <p className="mt-4 text-base text-white/70 sm:mt-6">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="mt-10 flex justify-center">
              <a
                href="/"
                className="text-xl font-semibold leading-7 text-white"
              >
                <span aria-hidden="true">&larr;</span> Back
              </a>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
