import { useNavigate } from "react-router-dom";
import { ANDROID_URL, IOS_URL } from "../constants";

interface Props {
  username: string;
  text?: string;
}

export default function SubscribeButton({
  username,
  text = "Subscribe to my fitness app",
}: Props) {
  const navigate = useNavigate();
  function handleSubscribePress() {
    if (navigator.userAgent.includes("Instagram")) {
      // @ts-ignore
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // @ts-ignore
      const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
      const isAndroid = /Android/.test(userAgent);

      if (isIOS) {
        window.location.href = IOS_URL;
      } else if (isAndroid) {
        window.location.href = ANDROID_URL;
      }
    } else {
      navigate(`/${username}/subscriptions`);
    }
  }

  return (
    <div className="mt-6 flex flex-col justify-center px-4 sm:flex-row ">
      <button
        onClick={handleSubscribePress}
        type="button"
        className="rounded-md bg-gradient-to-r from-red-400 to-orange-400 px-3.5 py-3 text-sm font-semibold text-white font-poppins items-center shadow-sm hover:bg-gray-100"
      >
        <span className="flex items-center justify-center space-x-1">
          {text} &rarr;
        </span>
      </button>
    </div>
  );
}
